import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

import { tap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from './user.service';
import { AuthIntereptor } from '../interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  role = 'role';

  constructor(private router: Router, private apiService: ApiService, private userService: UserService) {
  }

signin() {
  window.localStorage.setItem('loginStatus',"1");
}

authenticate(email, password) {
  console.log('Authenticate function')
      return this.apiService.post(`api/login`, { "email": email, "password": password })
      .pipe(
          tap((res: any) => {
              //console.log(JSON.stringify(res))
              window.localStorage.setItem(this.role, res.user.role);
              window.localStorage.setItem('accessToken', res.access_token);
              AuthIntereptor.accesToken = res.access_token;
              this.signin();
          }),
      )
}

signout() {
  window.localStorage.removeItem(this.role);
  window.localStorage.removeItem('loginStatus');

  //also clean the store
  this.router.navigate(['', 'login']);
}

isAthorized(): boolean {
  if(localStorage.getItem('loginStatus') == "1") {
    return true;
  }
  return false;
  //return JSON.parse(sessionStorage.getItem('authorised'));
}

canActivate(x) {
  // at first call, we try to get data -
  // if it is successfull, then we are already authorized and token is valid
  //we return this observable, because we not know the result before reply
  // if (!JSON.parse(sessionStorage.getItem('inicialized'))) {
  //   //console.log('not inicialized');
  //     return this.userService.getUserInfo()
  //         .pipe(
  //             //in success, we set data with signin, and return true
  //             map(() => {
  //                 this.signin();
  //                 return true;
  //             }),
  //             //or on error, we reset login data and return false
  //             catchError(() => {
  //                 this.signout();
  //                 return of(false)
  //             })
  //         )
  // }
  //here we have "cached" state - so simple return boolean - ususally not reached
  if (!this.isAthorized()) {
      this.router.navigate(['', 'login']);
  }
  return this.isAthorized();
}
}
