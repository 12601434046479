import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {

  stores = [];
  formGroup: UntypedFormGroup;
  displayStyle:string = "none";
  title:string = "";
  id:number = 0;
  constructor(private api: UserService) { }

  ngOnInit() {
    this.displayStyle = "none";
    this.formGroup = new UntypedFormGroup({
      "name": new UntypedFormControl('',[
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ]),
      "address": new UntypedFormControl('',[
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ]),
      "e-mail": new UntypedFormControl('',[
        Validators.required
      ]),
      "telephone": new UntypedFormControl('',[
        Validators.required,
        Validators.pattern(/^[0-9]+$/u)
      ])
    })
    this.api.getStores().subscribe(res => {
      this.stores = res;
    })
  }

  showModal(id){
    this.id = id;
    this.displayStyle = "block";
    if(id == 0) { //creating new store
      this.title = "Nový obchod"
    } else { //editing existing store
      this.title = "Editácia obchodu s číslom "+id;
      this.api.getStore(id).subscribe(res => {
        //console.log(JSON.stringify(res));
        this.formGroup.setValue({"name": res.name, "address": res.address, "e-mail":res['e-mail'],"telephone":res.telephone});
      })
    }
  }

  closeModal(){
    this.ngOnInit();
  }

  onSubmit(){
    //  Object.keys(this.formGroup.controls).forEach(key => {
    //   const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
    //   if (controlErrors != null) {
    //     Object.keys(controlErrors).forEach(keyError => {
    //       console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
    //     });
    //   }
    // });
    if(this.id == 0) { //creating
      console.log('creating')
      this.api.addStore(this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    } else { //updating
      console.log('updating')
      this.api.updateStore(this.id,this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    }
  }

}
