import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-alternatives',
  templateUrl: './alternatives.component.html',
  styleUrls: ['./alternatives.component.css']
})
export class AlternativesComponent implements OnInit {

  displayStyle:string = "none";
  formGroup: UntypedFormGroup;
  alts = [];
  title:string = "";
  id:number = 0;

  constructor(private api: UserService) { }

  ngOnInit() {
    this.displayStyle = "none";
    this.formGroup = new UntypedFormGroup({
      "name": new UntypedFormControl('',[
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ])
    })
    this.api.getAlts().subscribe(res => {
      this.alts = res;
    })
  }

  showModal(id){
    this.id = id;
    this.displayStyle = "block";
    if(id == 0) { //creating new tag
      this.title = "Nový alt"
    } else { //editing existing store
      this.title = "Editovať alt s číslom "+id;
      this.api.getAlt(id).subscribe(res => {
        //console.log(JSON.stringify(res));
        this.formGroup.setValue({"name": res.name});
      })
    }
  }

  closeModal(){
    this.ngOnInit();
  }

  onSubmit(){
    if(this.id == 0) { //creating
      console.log('creating')
      this.api.addAlt(this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    } else { //updating
      console.log('updating')
      this.api.updateAlt(this.id,this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    }
  }

}
