import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {

  product = [];
  thumbnails = [];
  id: number = 0;
  formGroup: UntypedFormGroup;
  imgURL='';
  template = 'one';
  displayStyle = "none";

  ancestors = [];
  descendants = [];
  timestamp;

  constructor(private api: UserService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(data => {
      this.id = data.id;
      if(this.id == -1) {
        this.template = 'more';
      } else this.template = 'one';
    })
    this.timestamp = (new Date()).getTime();
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      'item_id': new UntypedFormControl(0,[
        Validators.required,
        Validators.pattern(/^[0-9]*$/)
      ]),
      'name': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ]),
      'SKU': new UntypedFormControl(0, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/)
      ]),
      'category_id': new UntypedFormControl(1,[
        Validators.required,
        Validators.pattern(/^[0-9]*$/)
      ]),
      'short_desc': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
      ]),
      'description': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
      ]),
      'manufactor': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
      ]),
      'spec_info': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
      ]),
      'imgurl': new UntypedFormControl('',[
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ]),
      'quantity': new UntypedFormControl(1, [
        Validators.required,
      ]),
      'unit': new UntypedFormControl('ks' ,[
        Validators.required
      ])
    })

    this.api.getProductDetail(this.id).subscribe(res => {
      if (this.id > 0) this.product = res['product'];
      this.imgURL = res['image'] ? res['image'] : res['product']['imgurl'];
      this.thumbnails = res['thumbs'];
      if (this.id > 0) {
        //new method
        this.formGroup.setValue({
          'item_id': this.product['item_id'],
          'name': this.product['name'],
          'SKU': this.product['SKU'],
          'category_id': this.product['category'].category_id,
          'short_desc': this.product['short_desc'],
          'description': this.product['description'],
          'manufactor': this.product['manufactor'],
          'spec_info': this.product['spec_info'],
          'imgurl': this.product['imgurl'],
          'quantity': this.product['quantity'] ? this.product['quantity'] : 1,
          'unit': this.product['unit'] ? this.product['unit'] : 'ks'
        })
        //old method
        // this.formGroup = new FormGroup({
        //   'item_id': new FormControl(this.product['item_id'], [
        //     Validators.required,
        //     Validators.pattern(/^[0-9]*$/)
        //   ]),
        //   'name': new FormControl(this.product['name'], [
        //     Validators.required,
        //     Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
        //   ]),
        //   'SKU': new FormControl(this.product['SKU'], [
        //     Validators.required,
        //     Validators.pattern(/^[0-9]*$/),
        //   ]),
        //   'category_id': new FormControl(this.product['category'].category_id,[
        //     Validators.required,
        //     Validators.pattern(/^[0-9]*$/)
        //   ]),
        //   'short_desc': new FormControl(this.product['short_desc'], [
        //     Validators.required,
        //     Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
        //   ]),
        //   'description': new FormControl(this.product['description'], [
        //     Validators.required,
        //     Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
        //   ]),
        //   'manufactor': new FormControl(this.product['manufactor'], [
        //     Validators.required,
        //     Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
        //   ]),
        //   'spec_info': new FormControl(this.product['spec_info'], [
        //     Validators.required,
        //     Validators.pattern(/^[[0-9a-zA-Z\W+]+$/u)
        //   ]),
        //   'imgurl': new FormControl(this.product['imgurl'],[
        //     Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
        //   ]),
        //   'quantity': new FormControl(this.product['quantity'], [
        //     Validators.required,
        //   ]),
        //   'unit': new FormControl('ks' ,[
        //     Validators.required
        //   ])
        // })
      }
      this.getCategoriesFor(this.formGroup.get('category_id').value)
    })
  }

  getCategoriesFor(category_id){
    this.formGroup.controls['category_id'].setValue(category_id);
    this.api.getCategoryOptions(category_id).subscribe(res => {
      this.ancestors = res.ancestors;
      this.descendants = res.descendants;
    })
  }

  onSubmit() {
    // Object.keys(this.formGroup.controls).forEach(key => {
    //   const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
    //   if (controlErrors != null) {
    //     Object.keys(controlErrors).forEach(keyError => {
    //       console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
    //     });
    //   }
    // });
    //console.log(this.formGroup)



    //console.log(this.formGroup.value);
    if (this.id == 0) { //create product
      this.api.createProduct(this.formGroup.value).subscribe(res => {
        console.log(res);
        //this.router.navigate(['products/'+res['item_id']]);
        this.router.navigate([`products/category/${this.formGroup.value.category_id}/`+res['item_id']])
      })
    } else { //update product
     console.log('should update')
      this.api.updateProduct(this.id,this.formGroup.value).subscribe(res => {
          //this.router.navigate(['products/'+this.id]);
          this.router.navigate([`products/category/${this.formGroup.value.category_id}/${this.id}`]);
        })
    }
  }

  fileSelect(event, type){
    //console.log(event);
    let file =  event.target.files[0];
    if ( file.size > 10000000) {
      //TODO display error
      alert("Viac ako 10M");
      return;
    }

    this.api.uploadProductImg(this.id, file, type).subscribe(res => {
      window.location.reload();
    })
  }

  removeThumb(file) {
    if(confirm("Naozaj chcete vymazať súbor?")) {
        //console.log('should remove file: '+file )
        this.api.removeThumb(this.id,file).subscribe(res => {
          this.ngOnInit();
        })
    }
  }

  closeModal(){
    //console.log('modal closing')
    this.displayStyle = "none";
  }

  showModal(){
    //console.log('modal showing')
    this.displayStyle = "block";
  }

  removeProduct(){
    //console.log('product should remove');
    this.displayStyle = "none";
    this.api.removeProduct(this.id).subscribe(res => {
      this.router.navigate(['products']);
    })
  }

  // fileConverter(event, type) {
  //   let file = event.target.files[0];
  //   if (file.size > 10000000) {
  //     //TODO display error
  //     alert("Viac ako 10M");
  //     return;
  //   }
  //   this.readFile(file);
  // }

  // readFile(file){
  //   let metro = [];
  //   var fileReader: FileReader = new FileReader();
  //   fileReader.readAsText(file);
  //   fileReader.onload = ( (x) => {
  //     console.log(x)
  //     let html = x.target.result;
  //     console.log(html)
  //     let first = html.split('<tr class="divider">').pop(); //remove the header from html
  //     let count = (first.match(/<tr valign="top">/g) || []).length; //count how many items are in table

  //     let data = [];
  //     let items = [];

  //     for (let i = 1; i < count; i++) { //create array from rows of items
  //       data[i] = first.split('<tr valign="top">')[i];
  //     }

  //     data.forEach((r, index) => {
  //       var count1 = (r.match(/<td/g) || []).length;
  //       items[index] = [];
  //       for (let i = 0; i < count1; i++) {
  //         items[index][i] = r.split('</td>')[i].split('>')[1].trim();
  //       }
  //     })
  //     metro = items.filter(function (f) {
  //       return f != null;
  //     })
  //     console.log(metro)
  //     console.log(JSON.stringify(metro))
  //   })
  // }
}
