import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url = `https://api.regiomarket.sk/`;

  constructor(protected http: HttpClient) { }

  get(path: string): Observable<any> {
    return this.http.get(`${this.url}${path}`).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
      // catchError(err => {
      //   return this.handleError(err);
      // })
    )

  }

  post(path: string, data) {
    //console.log(data)
    return this.http.post(`${this.url}${path}`, JSON.stringify(data)).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
      // catchError(err => {
      //   return this.handleError(err);
      // })
    )
  }

  postData(path: string, data) {
    return this.http.post(`${this.url}${path}`, data, { headers: { skip: "true" } }).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }



  put(path: string, data) {
    return this.http.put(`${this.url}${path}`, JSON.stringify(data)).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  delete(path: string) {
    return this.http.delete(`${this.url}${path}`).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  private handleError(error: any) {

    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server API error';
    console.error('Error handler msg:' + errMsg); // log to console instead
    return throwError(error);

    //old method
    // console.log("-------API error-----------");
    // console.log(error);
    // // In a real world app, we might use a remote logging infrastructure
    // // We'd also dig deeper into the error to get a better message
    // /*let errMsg = (error.message) ? error.message :
    //     error.status ? `${error.status} - ${error.statusText}` : 'Server API error';*/
    // // log to console instead

    // let errMsg;
    // try {
    //   //console.log(error._body)
    //   errMsg = JSON.parse(error._body);
    //   errMsg = errMsg.error
    // }
    // catch (e) {
    //   try {
    //     if (typeof error.error == 'object')
    //       errMsg = JSON.stringify(error.error)
    //     else
    //       errMsg = error.error
    //   }
    //   catch (e) {
    //     errMsg = error._body;
    //   }
    // }
    // // console.log(errMsg)
    // alert("Chyba: " + errMsg);
    // //alert(errMsg);
    // return observableThrowError(errMsg);
  }
}
