import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrl: './compare-modal.component.css'
})
export class CompareModalComponent implements OnInit{

  @Input() product_id;
  @Output() closed = new EventEmitter();

  stores;

  constructor(private api: UserService){

  }

  ngOnInit(){
    this.api.compareProductPrices(this.product_id).subscribe(res => {
      this.stores = res;
    })

  }

  ngOnDestroy()
  {
    this.stores = [];
  }

  closeModal() {
    console.log('should close tag modal')
    this.closed.emit();
  }
}
