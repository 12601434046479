import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-store-modal',
  templateUrl: './store-modal.component.html',
  styleUrls: ['./store-modal.component.css']
})
export class StoreModalComponent implements OnInit {

  @Output() closed = new EventEmitter();
  @Input() stores;
  @Input() stock_statuses;
  @Input() product_tags;
  @Input() sel_stores;
  @Input() product;

  tags = [];
  formGroup: any;

  constructor(private api: UserService) { }

  ngOnInit() {
    console.log(this.product);
    console.log(this.sel_stores)
    this.formGroup = new UntypedFormGroup({
      'item_id': new UntypedFormControl(this.product.item_id, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      'store_id': new UntypedFormControl(0, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      'stock_status': new UntypedFormControl(1, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      'plu': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/)
      ]),
      'VAT': new UntypedFormControl(20, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
      ]),
      'buying_price': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)
      ]),
      'selling_price': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)
      ])
    })
    console.log(this.product)
    //if we edit the existing, we need to initialize the formGroup
    if(this.product.store_id) this.initializeForm();
  }

  initializeForm(){
    this.formGroup.setValue({
      "item_id": this.product.item_id,
      "store_id": this.product.store_id,
      "stock_status": this.product.stock_status,
      "plu": this.product.plu,
      "VAT": this.product.VAT,
      "buying_price": this.product.buying_price,
      "selling_price": this.product.selling_price })
      this.formGroup.controls["store_id"].disable();
  }

  closeModal() {
    console.log('should close tag modal')
    this.closed.emit();
  }

  onSubmit() {
    if(this.formGroup.controls["store_id"].disabled) {
      //should update
      this.formGroup.controls["store_id"].enable();
      this.api.updateProductForStore(this.formGroup.value, this.formGroup.controls["store_id"].value, this.formGroup.controls["item_id"].value).subscribe(res => {
        //this.displayStyle = "none";
        this.closeModal();
      })
    } else {
      this.api.addProductForStore(this.formGroup.value).subscribe(res => {
        //this.displayStyle = "none";
        //console.log(JSON.stringify(res));
        this.closeModal();
      })
    }
  }

}
