import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  user:any;
  darkSwitch:boolean = false;
  loggedIN = false;
  displayStyle = "none";

  constructor(private api: UserService, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event['url'] == '/login') {
          //console.log('login false')
          this.loggedIN = false;
        }
        else {
         // console.log('login true')
          this.loggedIN = true;
          //this.getUserInfo();
        }
      }
    })
   }

  ngOnInit() {
    //console.log(this.loggedIN);
    if(this.loggedIN) this.getUserInfo();
  }

  changeTheme() {
    console.log('function entered')
    this.darkSwitch = !this.darkSwitch;
    if(this.darkSwitch) {
      document.body.setAttribute('data-theme','dark')
    }
    if(!this.darkSwitch) {
      document.body.removeAttribute('data-theme')
    }
  }

  getUserInfo(){
    //console.log('getting user info')
    this.api.getUserInfo().subscribe(data => {
      this.user = data;
    })
  }

  signOut(){
    this.displayStyle = "none";
    this.router.navigate(['/login']);
  }

  showModal(){
    //console.log('modal showing')
    this.displayStyle = "block";
  }

  closeModal(){
    //console.log('modal closing')
    this.displayStyle = "none";
  }

}
