import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email;
  password;
  unsuccesfull = false;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    console.log('should sign out');
    this.authService.signout();
  }

  authenticate(){
    this.authService.authenticate(this.email, this.password)
    .subscribe(()=>{
      this.router.navigate(['/products'])
      },
               ()=>{this.unsuccesfull=true});
}

}
