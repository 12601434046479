import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  categories = [];
  displayStyle = "none";
  title = '';
  formGroup: UntypedFormGroup;
  id: number = -1;
  type: number = 0;

  ancestors = [];
  descendants = [];

  constructor(private api: UserService) { }

  ngOnInit() {
    this.displayStyle = "none";
    this.id = -1;
    this.formGroup = new UntypedFormGroup({
      'title': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ]),
      'parent_id': new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+$/u)
      ])
    });

    this.api.getCategories().subscribe(res => {
      this.categories = res;
      //console.log(this.categories);
    })
  }

  getCategoriesFor(category_id){
    this.formGroup.controls['parent_id'].setValue(category_id);
    this.api.getCategoryOptions(category_id).subscribe(res => {
      this.ancestors = res.ancestors;
      this.descendants = res.descendants;
    })
  }

  showModal(type, category) {
    this.displayStyle = "block";
    this.id = category.id;
    this.type = type;
    if (this.type == 0) { //creating new category
      this.title = "Nová kategória";
      this.formGroup.controls['parent_id'].setValue(category.id);
      this.getCategoriesFor(category.id)
    } else if (this.type == 1) { //updating category
      this.title = "Editácia kategórie: " + this.id + ' - '+category.title;
      this.formGroup.setValue({'parent_id': category.parent_id ,'title': category.title });
      this.getCategoriesFor(category.parent_id)
    }
    else alert("Neznáma chyba!")
  }

  onSubmit() {
    //console.log(JSON.stringify(this.formGroup.value));
    if (this.type == 0) { //creating
      console.log('creating')
      this.api.addCategory(this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    } else if(this.type == 1) { //updating
      console.log('updating')
      this.api.updateCategory(this.id, this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    }
    else alert("Neznáma chyba!");
  }

  closeModal() {
    this.ngOnInit();
  }

}
