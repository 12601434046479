// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbutton {
  position: fixed;
  bottom: 100px;
  right: 50px;
}
.nav-link {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  color: #000;
  margin-right: 10px;
  display: block;
}
.nav-pills-rounded .nav-item .nav-link {
  border-radius: 22px;
  font-weight: 700;
  padding: 10px 15px;
}
.nav-pills-rounded .nav-item .nav-link:hover {
  color: #132d5c;
  border-color: #132d5c;
}
`, "",{"version":3,"sources":["webpack://./src/app/actions/actions.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".topbutton {\n  position: fixed;\n  bottom: 100px;\n  right: 50px;\n}\n.nav-link {\n  background-color: #fff;\n  border: 1px solid #e6e6e6;\n  color: #000;\n  margin-right: 10px;\n  display: block;\n}\n.nav-pills-rounded .nav-item .nav-link {\n  border-radius: 22px;\n  font-weight: 700;\n  padding: 10px 15px;\n}\n.nav-pills-rounded .nav-item .nav-link:hover {\n  color: #132d5c;\n  border-color: #132d5c;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
