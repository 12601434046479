import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriesComponent } from './categories/categories.component';
import { LoginComponent } from './login/login.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductsComponent } from './products/products.component';
import { RolesGuard } from './roles.guard';
import { AuthService } from './services/auth.service';
import { StoresComponent } from './stores/stores.component';
import { TagsComponent } from './tags/tags.component';
import { UsersComponent } from './users/users.component';
import { AlternativesComponent } from './alternatives/alternatives.component';
import { ActionsComponent } from './actions/actions.component';
import { RecipesComponent } from './recipes/recipes.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthService],
    children: [
      {path: '', redirectTo: 'products', pathMatch: 'full' },
      {path: 'products', component: ProductsComponent, canActivate: [RolesGuard], data: {roles: ['uploader','admin','partner']}},
      {path: 'products/category/:category_id', component: ProductsComponent, canActivate: [RolesGuard], data: {roles: ['uploader','admin','partner']}},
      {path: 'products/category/:category_id/store/:store_id', component: ProductsComponent, canActivate: [RolesGuard], data: {roles: ['uploader','admin','partner']}},
      {path: 'products/category/:category_id/:item_id', component: ProductsComponent, canActivate: [RolesGuard], data: {roles: ['uploader','admin','partner']}},
      {path: 'products/:item_id', component: ProductsComponent, canActivate: [RolesGuard], data: {roles: ['uploader','admin']}},
      {path: 'product/edit/:id', component: ProductEditComponent, canActivate: [RolesGuard], data: {roles: ['uploader','admin']}},
      {path: 'categories', component: CategoriesComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'tags', component: TagsComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'stores', component: StoresComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'users', component: UsersComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'alternatives', component: AlternativesComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'actions', component: ActionsComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'actions/category/:category_id/store/:store_id', component: ActionsComponent, canActivate: [RolesGuard], data: {roles: ['admin']}},
      {path: 'recipes', component:RecipesComponent, canActivate: [RolesGuard], data: {roles:['admin']}}
    ]
  },
  {path: 'login' , component: LoginComponent},
  {path: '**', redirectTo: 'products'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
