// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li > a.custom-active {
  color: black;
}

.nav-item:hover a {
  color: rgb(73, 73, 73);
}
`, "",{"version":3,"sources":["webpack://./src/app/navbar/navbar.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["li > a.custom-active {\n  color: black;\n}\n\n.nav-item:hover a {\n  color: rgb(73, 73, 73);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
