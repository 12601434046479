import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productfilter'
})
export class ProductfilterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value.filter((item) => {
      if (args[0].length > 0) {
        let num_check = !isNaN(Number(args[0]));
        if(num_check) { //searching with barcode
          let i;
          i = item.SKU.toString();
          if (!i.includes(args[0])) {
            return false;
          } else {
            return true;
          }
        } else { //searching with name
          if(item.name.toLowerCase().includes(args[0])) {
            return true;
          }
          return false;
        }
      }
      return false;
    });
  }

}
