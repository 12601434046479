import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

@Injectable()
export class AuthIntereptor implements HttpInterceptor {

  static accesToken = '';
  refresh:boolean = false;

  constructor(protected http: HttpClient) {
    if(window.localStorage.getItem('accessToken')) AuthIntereptor.accesToken = window.localStorage.getItem('accessToken');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${AuthIntereptor.accesToken}`
      }
    });


    if (!request.headers.get("skip")) {//if skip is in headers, we don't want to add content-type
      req = req.clone({
        setHeaders: {
          'Content-type': 'application/json'
        }
      })
    }




    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if(err.status === 401 && !this.refresh) {
        this.refresh = true;

        return this.http.post(`https://api.regiomarket.sk/api/refresh`, {}, {withCredentials: true}).pipe(
          switchMap((res: any) => {
            AuthIntereptor.accesToken = res.access_token;
            window.localStorage.setItem('accessToken', res.access_token)
            this.refresh = false;
            return next.handle(request.clone({
              setHeaders: {
                Authorization: `Bearer ${AuthIntereptor.accesToken}`,
                'Content-type': 'application/json',
              }
            }));
          })
        );
      }
      this.refresh = false;
      return throwError(() => err)
    }));
  }
}
