// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal{
  --bs-modal-width: 100% !important;
}
.modal-dialog{
  margin-top: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/alt-modal/alt-modal.component.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".modal{\n  --bs-modal-width: 100% !important;\n}\n.modal-dialog{\n  margin-top: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
