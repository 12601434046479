import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup,Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  displayStyle:string = "none";
  formGroup: UntypedFormGroup;
  tags = [];
  title:string = "";
  id:number = 0;

  constructor(private api: UserService) { }

  ngOnInit() {
    this.displayStyle = "none";
    this.formGroup = new UntypedFormGroup({
      "name": new UntypedFormControl('',[
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z\W+]+$/u)
      ])
    })
    this.api.getTagsWithCount().subscribe(res => {
      this.tags = res;
    })
  }

  showModal(id){
    this.id = id;
    this.displayStyle = "block";
    if(id == 0) { //creating new tag
      this.title = "Nový tag"
    } else { //editing existing store
      this.title = "Editovať tag s číslom "+id;
      this.api.getTag(id).subscribe(res => {
        //console.log(JSON.stringify(res));
        this.formGroup.setValue({"name": res.name});
      })
    }
  }

  closeModal(){
    this.ngOnInit();
  }

  onSubmit(){
    if(this.id == 0) { //creating
      console.log('creating')
      this.api.addTag(this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    } else { //updating
      console.log('updating')
      this.api.updateTag(this.id,this.formGroup.value).subscribe(res => {
        this.ngOnInit();
      })
    }
  }

}
