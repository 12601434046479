import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'regiomarket';

  constructor(){}

  ngOnInit(){
    let host = window.location.host;
    if ( host != 'www.regiomarket.sk' && host != 'localhost:4200'){
      window.location.host = 'www.regiomarket.sk';
    }
  }
}
