import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alt-modal',
  templateUrl: './alt-modal.component.html',
  styleUrls: ['./alt-modal.component.css']
})
export class AltModalComponent implements OnInit {

  @Output() closed = new EventEmitter();
  @Input() products;
  timestamp

  constructor() {
    this.timestamp = (new Date()).getTime();
   }

  ngOnInit() {
  }

  closeModal() {
    console.log('should close tag modal')
    this.closed.emit();
  }

}
