import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrl: './actions.component.css'
})
export class ActionsComponent implements OnInit{


  stores = [];
  ancestors = [];
  descendants = [];
  category_id:number = 1;
  store_id: number = 0;
  products = [];
  filtered = [];

  constructor(private api: UserService, private route: ActivatedRoute) {
    this.route.params.subscribe( params => {
      if(params.category_id) {
        this.category_id = params.category_id;
        this.store_id = params.store_id;
        this.getProducts()
      } else {
        this.getProducts()
      }
    })
  }

  resetFilter() {
    this.category_id = -1;
    this.store_id = 0;
  }


  ngOnInit(){

    this.api.getStores().subscribe((res) => {
        this.stores = res;
        this.getProducts();
    })
  }

  async getProducts(){
    let parents = await  this.api.getCategoryOptions(this.category_id).toPromise();
    this.ancestors = await parents.ancestors;
    this.descendants = await parents.descendants;
    this.descendants.sort((a,b) => a.title.localeCompare(b.title));
    this.products = await this.api.getProductsInAction(this.category_id,this.store_id).toPromise()
    this.loadList();
  }

  loadList() {
    //this.filtered = this.products.slice(0, 50);
    this.filtered = this.products;
  }


  getStoreName(id) {
    let index = this.stores.findIndex(x => x.store_id == id)
    if(index !== -1) {
      return this.stores[index].name;
    }

    return "Chyba";
  }

}
