import { Component, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { forkJoin } from 'rxjs';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users = [];
  stores = [];
  user_stores = [];
  user = {user_id: 0, name: ''};
  displayStyle = 'none';

  closed = new EventEmitter();
  storeArray = new UntypedFormArray([]);

  constructor(private api: UserService) {
    forkJoin(
      this.api.getUsers(),
      this.api.getStores()
    ).subscribe(res => {
      this.users = res[0];
      this.stores = res[1];
      //console.log(this.users,this.stores)
    })
  }

  ngOnInit() {

  }

  showModal() {
    this.initializeArray();
  }

  initializeArray() {
    if (!this.user_stores) { // if there are no tags, instert an empty row
      this.addRow();
    } else { // if there are tags, insert them
      for (let i = 0; this.user_stores.length > i; i++) {
        const group = new UntypedFormGroup({
          user_id: new UntypedFormControl(this.user.user_id,[
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ]),
          store_id: new UntypedFormControl(this.user_stores[i], [
            Validators.required,
            Validators.pattern(/^[0-9]*$/),
            Validators.min(0)
          ])
        });
        this.storeArray.insert(i, group)
      }
    }
  }

  checkIfAlreadyExists() {
    this.stores.forEach(v => {
      v.disabled = false;
      for(let i = 0; this.storeArray.value.length > i; i++) {
        if(this.storeArray.value[i].store_id == v.store_id){
          v.disabled = true;
        }
      }
    })
  }

  addRow() {
    console.log('add row entered')
    this.checkIfAlreadyExists();
    const group = new UntypedFormGroup({
      user_id: new UntypedFormControl(this.user.user_id, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ]),
      store_id: new UntypedFormControl(0, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.min(0)
      ])
    });
    const index = this.storeArray.length + 1
    this.storeArray.insert(index, group);

  }

  removeRow(i) {
    this.storeArray.removeAt(i);
    this.checkIfAlreadyExists();
  }

  closeModal() {
    console.log('should close tag modal')
    this.closed.emit();
    this.displayStyle = 'none';
    this.storeArray.clear();
  }

  onSubmit() {
    this.api.updateStoresForUser(this.storeArray.value).subscribe(res => {
      this.closeModal();
    })
  }

  getStoresForUser(uid,name) {
    this.user.user_id = uid;
    this.user.name = name;
    this.api.getStoresForUser(uid).subscribe(res => {
      this.user_stores = res;
      this.displayStyle = 'block';
      this.showModal();
    })
  }

}
